import React, { useState } from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  Box,
  List,
  ListItem,
  ListItemText,
  Chip,
  Divider
} from '@mui/material';
import {
  PhotoCamera,
  Videocam,
  EmojiEvents,
  Create,
} from '@mui/icons-material';

interface TimelineEvent {
  id: string;
  type: 'photo' | 'video' | 'milestone' | 'diary';
  title: string;
  date: string;
  description: string;
  mediaUrl?: string;
  thumbnailUrl?: string;
  tags?: string[];
}

const TimelinePage = () => {
  const [filter, setFilter] = useState<string | null>(null);

  // Timeline events data
  const events: TimelineEvent[] = [
    {
      id: '1',
      type: 'milestone',
      title: 'Mom is Probably Pregnant Today',
      date: 'June 24, 2024',
      description: 'A special day when we first discovered the possibility of our upcoming joy. The beginning of our amazing journey into parenthood.',
      mediaUrl: process.env.PUBLIC_URL + '/images/20240624Pregnancy.jpg',
      thumbnailUrl: process.env.PUBLIC_URL + '/images/20240624Pregnancy.webp',
      tags: ['FirstMoment', 'Pregnancy', 'NewBeginning']
    },
    {
      id: '2',
      type: 'milestone',
      title: 'Mom and Dad, I am your Baby Boy! ',
      date: 'October 30, 2024',
      description: 'Today we had our first ultrasound, and we got the most amazing news - we\'re having a baby boy! Seeing his tiny form on the screen made our hearts overflow with joy and love.',
      mediaUrl: process.env.PUBLIC_URL + '/images/20241030Boy.png',
      thumbnailUrl: process.env.PUBLIC_URL + '/images/20241030Boy.webp',
      tags: ['Ultrasound', 'BabyBoy', 'FirstLook', 'MilestoneMoment']
    }
  ];

  const getIcon = (type: string) => {
    switch (type) {
      case 'photo':
        return <PhotoCamera />;
      case 'video':
        return <Videocam />;
      case 'milestone':
        return <EmojiEvents />;
      case 'diary':
        return <Create />;
      default:
        return <Create />;
    }
  };

  const filteredEvents = filter
    ? events.filter((event) => event.type === filter)
    : events;

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Timeline
      </Typography>

      <Box sx={{ mb: 4, display: 'flex', gap: 1 }}>
        <Chip
          label="All"
          onClick={() => setFilter(null)}
          color={filter === null ? 'primary' : 'default'}
          variant={filter === null ? 'filled' : 'outlined'}
        />
        <Chip
          icon={<PhotoCamera />}
          label="Photos"
          onClick={() => setFilter('photo')}
          color={filter === 'photo' ? 'primary' : 'default'}
          variant={filter === 'photo' ? 'filled' : 'outlined'}
        />
        <Chip
          icon={<Videocam />}
          label="Videos"
          onClick={() => setFilter('video')}
          color={filter === 'video' ? 'primary' : 'default'}
          variant={filter === 'video' ? 'filled' : 'outlined'}
        />
        <Chip
          icon={<EmojiEvents />}
          label="Milestones"
          onClick={() => setFilter('milestone')}
          color={filter === 'milestone' ? 'primary' : 'default'}
          variant={filter === 'milestone' ? 'filled' : 'outlined'}
        />
        <Chip
          icon={<Create />}
          label="Diary"
          onClick={() => setFilter('diary')}
          color={filter === 'diary' ? 'primary' : 'default'}
          variant={filter === 'diary' ? 'filled' : 'outlined'}
        />
      </Box>

      <List>
        {filteredEvents.map((event, index) => (
          <React.Fragment key={event.id}>
            {index > 0 && <Divider />}
            <ListItem>
              <Card 
                sx={{ 
                  width: '100%', 
                  position: 'relative',
                  overflow: 'hidden',
                  backgroundImage: `url(${event.mediaUrl})`,
                  backgroundSize: '100% 100%',
                  backgroundPosition: 'center',
                  backgroundRepeat: 'no-repeat',
                  minHeight: '600px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  transition: 'all 0.3s ease-in-out',
                  cursor: 'pointer',
                  '&:hover': {
                    transform: 'scale(1.02)',
                    boxShadow: '0 8px 40px rgba(0,0,0,0.2)',
                    '& .card-overlay': {
                      background: 'rgba(255, 255, 255, 0.4)',
                    },
                    '& .card-content': {
                      transform: 'translateY(-10px)',
                    }
                  },
                  '&:active': {
                    transform: 'scale(0.98)',
                  }
                }}
              >
                <Box
                  className="card-overlay"
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 1,
                    background: 'rgba(255, 255, 255, 0.3)',
                    padding: 2,
                    backdropFilter: 'blur(1px)',
                    transition: 'all 0.3s ease-in-out'
                  }}
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                    {event.thumbnailUrl ? (
                      <img 
                        src={event.thumbnailUrl} 
                        alt="icon" 
                        style={{ 
                          width: 48, 
                          height: 48, 
                          borderRadius: '50%' 
                        }} 
                      />
                    ) : (
                      getIcon(event.type)
                    )}
                    <Typography 
                      variant="h5" 
                      sx={{ 
                        fontWeight: 700,
                        fontFamily: "'Dancing Script', cursive",
                        fontSize: '2.5rem',
                        color: event.title === 'Mom is Probably Pregnant Today' ? '#000000' : '#4FC3F7'
                      }}
                    >
                      {event.title}
                    </Typography>
                  </Box>
                  <Typography 
                    variant="body1" 
                    color="text.secondary" 
                    sx={{ 
                      mt: 1,
                      fontFamily: "'Playfair Display', serif",
                      fontSize: '1.2rem',
                      color: event.date === 'October 30, 2024' ? '#0D47A1' : 'text.secondary'
                    }}
                  >
                    {event.date}
                  </Typography>
                </Box>
                <CardContent 
                  className="card-content"
                  sx={{ 
                    position: 'absolute', 
                    bottom: 0, 
                    left: 0, 
                    right: 0,
                    background: 'rgba(255, 255, 255, 0.3)',
                    backdropFilter: 'blur(1px)',
                    padding: 2,
                    transition: 'all 0.3s ease-in-out'
                  }}
                >
                  <Typography 
                    variant="body1" 
                    sx={{ 
                      fontWeight: 700,
                      fontFamily: "'Quicksand', sans-serif",
                      fontSize: '1.1rem',
                      letterSpacing: '0.5px'
                    }}
                  >
                    {event.description}
                  </Typography>
                  {event.tags && (
                    <Box sx={{ mt: 2, display: 'flex', gap: 0.5, flexWrap: 'wrap' }}>
                      {event.tags.map((tag) => (
                        <Chip
                          key={tag}
                          label={`#${tag}`}
                          size="small"
                          variant="outlined"
                          color="primary"
                        />
                      ))}
                    </Box>
                  )}
                </CardContent>
              </Card>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default TimelinePage;
