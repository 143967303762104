import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  Box,
} from '@mui/material';
import {
  PhotoLibrary,
  Videocam,
  Timeline,
  EmojiEvents,
  Home,
} from '@mui/icons-material';

const Navigation = () => {
  return (
    <AppBar position="static" color="primary">
      <Container maxWidth="lg">
        <Toolbar>
          <Typography
            variant="h6"
            component={RouterLink}
            to="/"
            sx={{
              textDecoration: 'none',
              color: 'inherit',
              flexGrow: 1,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Home sx={{ mr: 1 }} />
            Our Son's Journey
          </Typography>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              color="inherit"
              component={RouterLink}
              to="/photos"
              startIcon={<PhotoLibrary />}
            >
              Photos
            </Button>
            <Button
              color="inherit"
              component={RouterLink}
              to="/videos"
              startIcon={<Videocam />}
            >
              Videos
            </Button>
            <Button
              color="inherit"
              component={RouterLink}
              to="/milestones"
              startIcon={<EmojiEvents />}
            >
              Milestones
            </Button>
            <Button
              color="inherit"
              component={RouterLink}
              to="/timeline"
              startIcon={<Timeline />}
            >
              Timeline
            </Button>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Navigation;
