import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Box,
  Modal,
  IconButton,
} from '@mui/material';
import { Close, PlayArrow } from '@mui/icons-material';

interface Video {
  id: string;
  title: string;
  date: string;
  thumbnailUrl: string;
  videoUrl: string;
  description: string;
}

const Videos = () => {
  const [selectedVideo, setSelectedVideo] = useState<Video | null>(null);

  // This will be replaced with actual video data from your storage
  const videos: Video[] = [
    // Add sample videos here
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Video Memories
      </Typography>

      <Grid container spacing={3}>
        {videos.map((video) => (
          <Grid item xs={12} sm={6} md={4} key={video.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
              onClick={() => setSelectedVideo(video)}
            >
              <Box sx={{ position: 'relative' }}>
                <CardMedia
                  component="img"
                  height="200"
                  image={video.thumbnailUrl}
                  alt={video.title}
                  sx={{ objectFit: 'cover' }}
                />
                <IconButton
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    bgcolor: 'rgba(0,0,0,0.5)',
                    '&:hover': {
                      bgcolor: 'rgba(0,0,0,0.7)',
                    },
                  }}
                >
                  <PlayArrow sx={{ color: 'white', fontSize: 40 }} />
                </IconButton>
              </Box>
              <CardContent>
                <Typography variant="h6">{video.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {video.date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={!!selectedVideo}
        onClose={() => setSelectedVideo(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          {selectedVideo && (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                  bgcolor: 'rgba(0,0,0,0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0,0,0,0.7)',
                  },
                }}
                onClick={() => setSelectedVideo(null)}
              >
                <Close />
              </IconButton>
              <video
                controls
                style={{
                  maxWidth: '100%',
                  maxHeight: 'calc(90vh - 100px)',
                }}
              >
                <source src={selectedVideo.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
              <Typography variant="h6" sx={{ mt: 2 }}>
                {selectedVideo.title}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {selectedVideo.description}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {selectedVideo.date}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Videos;
