import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Button,
  Box,
} from '@mui/material';
import { PhotoLibrary, Videocam, Timeline, EmojiEvents } from '@mui/icons-material';

const Home = () => {
  const features = [
    {
      title: 'Photo Gallery',
      icon: <PhotoLibrary sx={{ fontSize: 40 }} />,
      description: 'Browse through our collection of precious moments',
      link: '/photos',
    },
    {
      title: 'Video Memories',
      icon: <Videocam sx={{ fontSize: 40 }} />,
      description: 'Watch videos of special moments and milestones',
      link: '/videos',
    },
    {
      title: 'Milestones',
      icon: <EmojiEvents sx={{ fontSize: 40 }} />,
      description: 'Track important achievements and developments',
      link: '/milestones',
    },
    {
      title: 'Timeline',
      icon: <Timeline sx={{ fontSize: 40 }} />,
      description: 'View our journey chronologically',
      link: '/timeline',
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ textAlign: 'center', mb: 6 }}>
        <Typography variant="h2" component="h1" gutterBottom>
          Welcome to Our Son's Journey
        </Typography>
        <Typography variant="h5" color="text.secondary" paragraph>
          A digital collection of memories, milestones, and precious moments
        </Typography>
      </Box>

      <Grid container spacing={4}>
        {features.map((feature) => (
          <Grid item xs={12} sm={6} md={3} key={feature.title}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                transition: '0.3s',
                '&:hover': {
                  transform: 'translateY(-5px)',
                  boxShadow: 3,
                },
              }}
            >
              <CardContent sx={{ flexGrow: 1, textAlign: 'center' }}>
                <Box sx={{ mb: 2, color: 'primary.main' }}>{feature.icon}</Box>
                <Typography gutterBottom variant="h5" component="h2">
                  {feature.title}
                </Typography>
                <Typography color="text.secondary" paragraph>
                  {feature.description}
                </Typography>
                <Button
                  component={RouterLink}
                  to={feature.link}
                  variant="contained"
                  color="primary"
                >
                  Explore
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Box sx={{ mt: 6, textAlign: 'center' }}>
        <Typography variant="h4" gutterBottom>
          Latest Updates
        </Typography>
        {/* Add recent updates or featured content here */}
      </Box>
    </Container>
  );
};

export default Home;
