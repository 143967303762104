import React from 'react';
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardMedia,
  List,
  ListItem,
  Divider,
  Box,
} from '@mui/material';
import { EmojiEvents } from '@mui/icons-material';

interface Milestone {
  id: string;
  title: string;
  date: string;
  description: string;
  imageUrl?: string;
  category: string;
}

const Milestones = () => {
  // This will be replaced with actual milestone data from your storage
  const milestones: Milestone[] = [
    // Add sample milestones here
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Milestones
      </Typography>

      <List>
        {milestones.map((milestone, index) => (
          <React.Fragment key={milestone.id}>
            {index > 0 && <Divider />}
            <ListItem>
              <Card sx={{ width: '100%' }}>
                {milestone.imageUrl && (
                  <CardMedia
                    component="img"
                    height="140"
                    image={milestone.imageUrl}
                    alt={milestone.title}
                  />
                )}
                <CardContent>
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <EmojiEvents color="primary" />
                    <Typography variant="h6">{milestone.title}</Typography>
                  </Box>
                  <Typography variant="subtitle2" color="primary" gutterBottom>
                    {milestone.category}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {milestone.date}
                  </Typography>
                  <Typography variant="body1" sx={{ mt: 1 }}>
                    {milestone.description}
                  </Typography>
                </CardContent>
              </Card>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Container>
  );
};

export default Milestones;
