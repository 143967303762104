import React, { useState } from 'react';
import {
  Container,
  Typography,
  Grid,
  Card,
  CardMedia,
  CardContent,
  Box,
  Modal,
  IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';

interface Photo {
  id: string;
  title: string;
  date: string;
  url: string;
  description: string;
}

const Photos = () => {
  const [selectedPhoto, setSelectedPhoto] = useState<Photo | null>(null);

  // This will be replaced with actual photo data from your storage
  const photos: Photo[] = [
    // Add sample photos here
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Photo Gallery
      </Typography>
      
      <Grid container spacing={3}>
        {photos.map((photo) => (
          <Grid item xs={12} sm={6} md={4} key={photo.id}>
            <Card
              sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                cursor: 'pointer',
                '&:hover': {
                  transform: 'scale(1.02)',
                  transition: 'transform 0.2s ease-in-out',
                },
              }}
              onClick={() => setSelectedPhoto(photo)}
            >
              <CardMedia
                component="img"
                height="200"
                image={photo.url}
                alt={photo.title}
                sx={{ objectFit: 'cover' }}
              />
              <CardContent>
                <Typography variant="h6">{photo.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {photo.date}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>

      <Modal
        open={!!selectedPhoto}
        onClose={() => setSelectedPhoto(null)}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            position: 'relative',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxWidth: '90vw',
            maxHeight: '90vh',
            overflow: 'auto',
          }}
        >
          {selectedPhoto && (
            <>
              <IconButton
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 8,
                  color: 'white',
                  bgcolor: 'rgba(0,0,0,0.5)',
                  '&:hover': {
                    bgcolor: 'rgba(0,0,0,0.7)',
                  },
                }}
                onClick={() => setSelectedPhoto(null)}
              >
                <Close />
              </IconButton>
              <img
                src={selectedPhoto.url}
                alt={selectedPhoto.title}
                style={{
                  maxWidth: '100%',
                  maxHeight: 'calc(90vh - 100px)',
                  objectFit: 'contain',
                }}
              />
              <Typography variant="h6" sx={{ mt: 2 }}>
                {selectedPhoto.title}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {selectedPhoto.description}
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                {selectedPhoto.date}
              </Typography>
            </>
          )}
        </Box>
      </Modal>
    </Container>
  );
};

export default Photos;
